import React from 'react';

import { ProductPod as DSProductPod } from '@johnlewispartnership/wtr-ingredients/ingredients/ProductPod';
import { usePodImpression } from 'hooks/use-pod-impression';
import { usePromotion } from 'components/Product/Promotion/use-promotion';
import { useDSPodProps } from './useDSPodProps';
import { makePodDataset } from './utils';

type ProductPodProperties = {
  imageLoading?: 'lazy' | 'eager' | undefined;
  lineNumber: number;
  position?: number;
  sponsored?: boolean;
  clickContext?: {
    type: string;
    personalisation: {
      type: string;
      name: string;
    };
  };
};

export const ProductPod = ({
  lineNumber,
  position = -1,
  sponsored = false,
  clickContext,
  imageLoading,
}: ProductPodProperties) => {
  const {
    product,
    conflict,
    trolleyQuantity,
    onFavouriteClick,
    onAddToTrolley,
    onUpdateTrolleyQuantity,
    productData,
    redOfferText,
  } = useDSPodProps({
    lineNumber,
    position,
    sponsored,
    clickContext,
  });
  const observeRef = usePodImpression(productData);
  const variant = 'light';
  const dataset = makePodDataset({
    hasPromotions: !!productData.promotions?.length,
    lineNumber: productData.lineNumber,
    name: productData.name,
    productType: productData.productType,
    productPosition: position,
    conflictMessage: conflict?.messages?.shortItem?.toLowerCase(),
    podType: variant,
  }) as unknown as DOMStringMap;

  const { backgroundColor, withChevron } = usePromotion({
    promotionTypeCode: product?.promotions?.[0]?.promotionTypeCode,
  }) as { backgroundColor: 'green' | 'red' | undefined; withChevron: boolean };

  return (
    <DSProductPod
      // conform to type @johnlewispartnership/wtr-ingredients/ingredients/ProductPod#ProductPodProps
      ref={observeRef}
      variant={variant}
      dataset={dataset}
      product={product}
      conflict={conflict}
      trolleyQuantity={trolleyQuantity}
      onFavouriteClick={onFavouriteClick}
      onAddToTrolley={onAddToTrolley}
      onUpdateTrolleyQuantity={onUpdateTrolleyQuantity}
      highlightColor={backgroundColor}
      withChevron={withChevron}
      redOfferText={redOfferText}
      imageLoading={imageLoading}
      trolleyControlsDisabled={false}
    />
  );
};
