import { useMakeSelector } from 'hooks/use-make-selector';
import { usePodClickTracking } from 'hooks/use-pod-click-tracking';
import useTrolleyActions from 'hooks/use-trolley-actions';
import { useDispatch, useSelector } from 'react-redux';
import { makeGetIsFavourite } from 'redux/modules/favourites-products/selectors';
import { addRemoveFavourite } from 'redux/modules/search-and-browse/actions/add-remove-favourite';
import { getIsRedPriceProduct } from 'redux/modules/entities/selectors/promotions';
import { getPageType } from 'utils/get-page-type';
import { getLocation } from 'redux/modules/routing/selectors';
import { getIsProductBoosted } from 'redux/modules/favourites/selectors/get-is-product-boosted';
import { makeGetDSConflict } from './selectors/selectConflict';
import { makeGetDSProduct } from './selectors/selectProduct';
import { updateTrolleyParams } from './utils';

// this returns object that conforms to props type of Design System (DS) pod
// @johnlewispartnership/wtr-ingredients/ingredients/ProductPod

export function useDSPodProps({ lineNumber, position, sponsored, clickContext }) {
  const dispatch = useDispatch();
  const wrapperId = getPageType(useSelector(getLocation)?.pathname);
  const isFavourite = useMakeSelector(makeGetIsFavourite, lineNumber);
  const boosted = useSelector(state => getIsProductBoosted(state, lineNumber));
  const { product, productData } = useMakeSelector(makeGetDSProduct, lineNumber);
  const redOfferText = useMakeSelector(() => getIsRedPriceProduct, lineNumber);
  const {
    addToTrolley,
    updateInTrolley,
    optimisticTrolleyQuantityAmount: amount,
    optimisticTrolleyQuantityUom: uomId = product.uoms.item,
  } = useTrolleyActions({ lineNumber, productId: product.productId });
  const conflict = useMakeSelector(makeGetDSConflict, product.productId, amount > 0, {
    isFavourite,
    position,
    sponsored,
    wrapperId,
    boosted,
  });
  const { reportClickToCitrus, reportAnalyticsClick } = usePodClickTracking({
    conflictMessage: conflict?.message?.toLowerCase(),
    isFavourite,
    position,
    productId: product.productId,
    wrapperId,
    boosted,
    sponsored,
  });

  // conform to type @johnlewispartnership/wtr-ingredients/ingredients/ProductPod/types.ts#Product
  product.sponsored = sponsored;
  product.favourite = isFavourite;
  product.productLinkProps.onClick = reportAnalyticsClick;

  // handlers

  const trolleyCtx = { productId: product.productId, lineNumber, position, clickContext };

  const onAddToTrolley = (q, e) => {
    addToTrolley(updateTrolleyParams(q, e, trolleyCtx));
    reportClickToCitrus();
    reportAnalyticsClick();
  };
  const onUpdateTrolleyQuantity = (q, e) => {
    updateInTrolley(updateTrolleyParams(q, e, trolleyCtx, 'newQuantity'));
    reportClickToCitrus();
  };

  const onFavouriteClick = () => {
    if (!isFavourite) reportClickToCitrus();
    reportAnalyticsClick();
    dispatch(addRemoveFavourite(isFavourite, lineNumber));
  };

  if (conflict?.resolution?.type === 'button') {
    conflict.resolution.action.onClick = e => onUpdateTrolleyQuantity({ amount: 0, uomId }, e);
  }

  return {
    product,
    conflict,
    trolleyQuantity: { amount, uomId },
    onFavouriteClick,
    onAddToTrolley,
    onUpdateTrolleyQuantity,
    productData,
    redOfferText,
  };
}
