import { createSelector } from 'reselect';
import env from 'env/env';
import {
  getAvailableUnits,
  getProductByLineNumber,
} from 'redux/modules/entities/selectors/products';
import { getEntertainingAttributes } from 'redux/modules/product-details/selectors/product-attributes';
import { isPersonalisableProduct } from 'utils/product';
import { ENTERTAINING } from 'constants/productTypes';

import { getPromotions, getPromotionWithPath } from 'redux/modules/entities/selectors/promotions';

import { trackOfferClick } from 'components/Product/Promotion';

import { getProductCookingStatus } from 'redux/modules/product-details/selectors/product-contents';
import servingInstructions from 'constants/servingInstructions';
import { PRODUCT_ATTRIBUTES } from 'constants/productAttributes';

import { isUserLoggedIn as isUserLoggedInSelector } from 'redux/modules/sessions/selectors';
import { getProductLinkProps, routerLinkWithProps } from 'utils/productLinkProps';
import { toDSPodUoms, mapDataToBadges, mapDataToTags } from '../utils';

//
// making DS product
//

// converts: wtr-website Product => wtr-ingredients Partial<Product>

// makes an object that conforms to a subset of type @johnlewispartnership/wtr-ingredients/ingredients/ProductPod/types.ts#Product
// the remaining attrs are filled in component/hook

const makeDSPromo = promo => ({
  ...promo,
  linkProps: routerLinkWithProps({
    to: `${env.root}${promo.promoPath}`,
    onClick: trackOfferClick(promo.promotionDescription),
  }),
});

const makeDSEntertainingAttributes = (attributes, cookingStatus) => {
  const { label: cookingLabel } =
    (cookingStatus && PRODUCT_ATTRIBUTES.find(({ type }) => type === cookingStatus)) || {};

  return {
    ...attributes,
    ...(attributes?.leadTime ? { leadTime: `${attributes.leadTime} notice` } : {}),
    ...(cookingLabel ? { [cookingStatus]: cookingLabel } : {}),
  };
};

export const makeGetDSProduct = () =>
  createSelector(
    getProductByLineNumber,
    getEntertainingAttributes,
    getProductCookingStatus,
    getAvailableUnits,
    getPromotions,
    isUserLoggedInSelector,
    (productData, entAttributes, cookingStatus, availableUoms, promotions, isUserLoggedIn) => {
      const {
        displayPrice,
        displayPriceEstimated,
        displayPriceQualifier,
        promotions: promoIds,
        currentSaleUnitPrice,
        pricing,
      } = productData;

      const entertaining = productData.productType === ENTERTAINING;

      const { name: productName, id: productId } = productData;
      return {
        product: {
          productId,
          lineNumber: productData.lineNumber,
          productName,
          productSize: productData.size,

          currentSaleUnitPrice: pricing?.currentSaleUnitRetailPrice ?? currentSaleUnitPrice,

          productPrice: {
            displayPrice,
            displayPriceEstimated,
            // removing parenthesis temporary
            // see src/components/Product/Promotion/PromotionElement.js#55
            displayPriceQualifier: (displayPriceQualifier || '').split(/[()]/).join(''),
          },
          imageUrl: productData.thumbnail,

          uoms: toDSPodUoms(availableUoms),

          productLinkProps: getProductLinkProps(isUserLoggedIn, productName, productId),

          promotions: promoIds.map(id => makeDSPromo(getPromotionWithPath(promotions, id))),

          entertainingAttributes: entertaining
            ? makeDSEntertainingAttributes(entAttributes, servingInstructions[cookingStatus])
            : {},

          reviews: productData.reviews,
          entertaining,

          isPersonalisableProduct: isPersonalisableProduct(productData),
          marketingBadges: mapDataToBadges(productData),
          productTags: mapDataToTags(productData),
          depositCharge: productData.depositCharge,
        },
        productData, // for further processing
      };
    },
  );
